<template>
  <div>
    <v-container>
      <h1 class="pb-6">Track and Trace</h1>

      <v-row>
        <v-col>
          <v-card rounded="lg" class="pa-6">
            <v-container>
              <v-row class="d-flex justify-space-between pb-8">
                <div class="pb-2">
                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 mb-2"
                            depressed
                            color="primary"
                          >
                            <v-icon left dark>mdi-cow</v-icon>
                            Create a New Shipment
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="headline ">
                            Create a New Shipment
                          </v-card-title>

                          <v-card-text>
                            <h4>Workflow Reference</h4>
                            <v-text-field></v-text-field>
                            <h4>Invoice Date</h4>
                            <v-text-field></v-text-field>
                            <h4>ETA Date</h4>
                            <v-text-field></v-text-field>
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" to="/shipment-details">
                              Start
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>

                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-pencil</v-icon>
                    Track Shipment
                  </v-btn>
                </div>
                <div class="pb-2">
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-eye</v-icon>
                    View
                  </v-btn>
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-pencil</v-icon>
                    Edit
                  </v-btn>
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-content-copy</v-icon>
                    Copy
                  </v-btn>
                </div>
              </v-row>
              <v-row>
                <v-col>20 Total Shipments</v-col>
                <v-divider vertical></v-divider>
                <v-col>5 Active Shipments</v-col>
                <v-divider vertical></v-divider>
                <v-col>15 Completed Objectives</v-col>
                <v-divider vertical></v-divider>
                <v-col>10 Processed Payments</v-col>
              </v-row>
              <v-divider></v-divider>
              <v-data-table
                :headers="headers"
                :items-per-page="5"
              ></v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Order ID #",
          align: "start",
          value: "1",
        },
        { text: "Date", value: "2" },
        { text: "Point of Destination", value: "3" },
        { text: "Date of Register", value: "4" },
      ],
    };
  },
};
</script>

<style></style>
